export default {
  AANVRAAG_INGEDIEND: "aanvraagIngediend",
  AANMELDING_INGEDIEND: "aanmeldingIngediend",
  AANMELDING_AFGEKEURD: "aanmeldingAfgekeurd",
  INSCHRIJVING_INGEDIEND: "inschrijvingIngediend",
  AANVRAAG_GEANNULEERD: "aanvraagGeannuleerd",
  AANVRAAG_VOLLEDIG: "aanvraagVolledig",
  AANVRAAG_GOEDGEKEURD: "aanvraagGoedgekeurd",
  AFREKENING_INGEDIEND: "afrekeningIngediend",
  AFREKENING_GOEDGEKEURD: "afrekeningGoedgekeurd",
  ONTBREKENDE_INFO: "extraInformatieNodig",
  ONTBREKENDE_INFO_INSCHRIJVING: "extraInformatieInschrijving",
  ONTBREKENDE_INFO_AANMELDING_OPGELADEN: "extraInformatieAanmeldingOpgeladen",
  ONTBREKENDE_INFO_INSCHRIJVING_OPGELADEN:
    "extraInformatieNodigInschrijvingOpgeladen",
  ONTBREKENDE_INFO_OPGELADEN: "extraInformatieOpgeladen",
  AANVRAAG_NIET_MOGELIJK: "aanvraagNietMogelijk",
  AANVRAAG_NIET_MOGELIJK_ONBEKENDE_PARTIJ:
    "aanvraagNietMogelijkOnbekendePartij",
  AANVRAAG_NIET_MOGELIJK_GEEN_NIEUWE_DOSSIERS:
    "aanvraagNietMogelijkGeenNieuweDossiers",
  AANMELDING_NIET_MOGELIJK_CUMUL_AANVRAAG:
    "aanmeldingNietMogelijkCumulAanvraag",
  AANMELDING_NIET_MOGELIJK_CUMUL_DRAFT: "aanmeldingNietMogelijkCumulDraft",
  PREMIE_AL_TOEGEKEND: "premieAlToegekend",
  AANMELDING_NIET_MOGELIJK_VANAF_1_JANUARI:
    "aanmeldingNietMogelijkVanaf1Januari",
  AANMELDING_INDIENEN_NIET_MOGELIJK_VANAF_1_JANUARI:
    "aanmeldingIndienenNietMogelijkVanaf1Januari",
};
